import React, {
  useState,
  useRef,
  useEffect,
  MouseEvent as ReactMouseEvent,
} from "react";
import WidgetHolder from "./components/WidgetHolder";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { dracula, a11yLight, CodeBlock } from "react-code-blocks";
import { useThemeMode } from "../../../_metronic/partials";
import { getCode } from "./core/_helpers";
import { getIframeSettings, postIframeSettings } from "./core/_requests";
import NotificationsContainer, {
  Color,
  NotificationsContainerHandle,
} from "../../../_metronic/partials/notification/Notification";
import { useQuery } from "react-query";
import ResizePopover from "../../../_metronic/partials/popover/ResizePopover";

type ErrorsType = { [key: number]: string };

export type iframeSettings = {
  color: string;
  height: number;
  theme: string;
};

const urlSchema = Yup.string().url("Invalid URL format").required("Required");

const getSettings = async (company: string) => {
  const response = await getIframeSettings(company);
  return response.data;
};

const WidgetEditor: React.FC = () => {
  const { mode } = useThemeMode();
  const { company } = useParams<{ company: string }>();

  const [height, setHeight] = useState<number>(450);
  const [color, setColor] = useState("#1B84FF");
  const [theme, setTheme] = useState("light");
  const [showAccordion, setShowAccordion] = useState(false);
  const [code, setCode] = useState(getCode(color, height, theme, company));
  const [widgetWidth, setWidgetWidth] = useState(
    window.innerWidth > 500 ? "90%" : "100%"
  );
  const [isResizing, setIsResizing] = useState(false);
  const [allowedOrigins, setAllowedOrigins] = useState([""]);
  const [errors, setErrors] = useState<ErrorsType>({});
  const [showPopover, setShowPopover] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const tempColorRef = useRef<string>(color);
  const widgetHolderRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<NotificationsContainerHandle | null>(null);

  const {
    data: fetchedSettings,
    isError,
    error,
  } = useQuery(["company", company], () => getSettings(company!), {
    suspense: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (fetchedSettings) {
      const { color, height, theme } = fetchedSettings.iframe_settings;
      const { origins } = fetchedSettings;
      if (height !== undefined) setHeight(height);
      if (color !== undefined) {setColor(color), tempColorRef.current = color};
      if (theme !== undefined) setTheme(theme);
      if (origins !== undefined) setAllowedOrigins(origins);
    }
  }, [fetchedSettings]);

  const createNewNotification = (color: Color, message: string) => {
    if (!notificationsRef.current) return;
    notificationsRef.current?.createNotification(color, message);
  };

  useEffect(() => {
    const parent = document.getElementById("kt_app_content_container");
    if (parent) {
      parent.classList.add("h-100");
      parent.style.paddingLeft = "0 !important";
    }

    return () => {
      if (parent) {
        parent.classList.remove("h-100");
        parent.style.paddingLeft = "20px";
      }
    };
  }, []);

  useEffect(() => {
    window.innerHeight < 690 ? setShowAccordion(false) : setShowAccordion(true);
    window.innerHeight < 690 ? setShowPopover(false) : setShowPopover(true);
  }, []);

  const handleMouseUp = () => {
    setColor(tempColorRef.current);
  };

  const copyCode = () => {
    navigator.clipboard.writeText(code);
  };

  useEffect(() => {
    setCode(getCode(color, height, theme, company));
  }, [color, height, theme, company]);

  useEffect(() => {
    const iframe = widgetHolderRef.current?.querySelector("iframe");
    if (isResizing) {
      if (iframe) {
        iframe.style.pointerEvents = "none";
      }

      document.body.style.cursor = "ew-resize";
      document.body.style.userSelect = "none";
      document.body.ondragstart = () => false;
    } else {
      if (iframe) {
        iframe.style.pointerEvents = "auto";
      }

      document.body.style.cursor = "default";
      document.body.style.userSelect = "auto";
      document.body.ondragstart = null;
    }
  }, [isResizing]);

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    const startX = "touches" in e ? e.touches[0].clientX : e.clientX;
    const computedStyle = window.getComputedStyle(widgetHolderRef.current!);
    const startWidth = parseFloat(computedStyle.width);
    setShowPopover(false);
    setIsResizing(true);

    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
      const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
      const newWidth = startWidth + (clientX - startX);
      setWidgetWidth(`${newWidth}px`);
    };

    const handleMouseUp = () => {
      setIsResizing(false);

      document.body.style.cursor = "default";
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("touchend", handleMouseUp);
  };

  const handleSave = () => {
    let hasError = false;
    const newErrors: ErrorsType = {};

    allowedOrigins.forEach((url, index) => {
      if (!urlSchema.isValidSync(url)) {
        newErrors[index] = "Invalid URL format";
        hasError = true;
      }
    });

    if (hasError) {
      createNewNotification(
        "error",
        "Please provide valid website URL(s) before saving settings."
      );
      setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
      return;
    }

    const iframeSettings = {
      color: color,
      height: height,
      theme: theme,
    };

    try {
      postIframeSettings(company!, iframeSettings, allowedOrigins);
      createNewNotification("success", "Settings saved successfully.");
    } catch {
      createNewNotification(
        "error",
        "Failed to save settings, please try again."
      );
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newOrigins = [
      ...allowedOrigins.slice(0, index),
      value,
      ...allowedOrigins.slice(index + 1),
    ];

    urlSchema
      .validate(value)
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [index]: "" }));
      })
      .catch((err) => {
        setErrors((prevErrors) => ({ ...prevErrors, [index]: err.message }));
      });

    setAllowedOrigins(newOrigins);
  };

  const handleRemove = (index: number) => {
    setAllowedOrigins((prev) => prev.filter((_, indexEl) => indexEl !== index));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index];
      return newErrors;
    });
  };

  return (
    <div
      ref={containerRef}
      className="d-flex flex-column w-100 h-100 px-3 pt-3"
    >
      <div className="accordion" id="kt_accordion_1">
        <div className="accordion-item">
          <h2 className="accordion-header" id="kt_accordion_1_header_1">
            <button
              className={`accordion-button fs-4 fw-semibold ${
                showAccordion ? "" : "collapsed"
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_1_body_1"
              aria-expanded={showAccordion}
              aria-controls="kt_accordion_1_body_1"
            >
              Widget preview
            </button>
          </h2>
          <div
            id="kt_accordion_1_body_1"
            className={`accordion-collapse collapse ${
              showAccordion ? "show" : ""
            }`}
            aria-labelledby="kt_accordion_1_header_1"
            data-bs-parent="#kt_accordion_1"
          >
            <div className="accordion-body pe-0 d-flex">
              <div
                ref={widgetHolderRef}
                id="widgetHolder"
                style={{
                  height: `${height}px`,
                  width: widgetWidth,
                  minWidth: 320,
                }}
              >
                <WidgetHolder
                  company={company}
                  theme={theme}
                  height={height}
                  color={color}
                  setTheme={(t) => setTheme(t)}
                />
              </div>
              {window.innerWidth > 500 && (
                <div
                  className="d-flex flex-column align-items-center justify-content-center pb-7 mx-5"
                  style={{ height: `${height}px` }}
                >
                  <div className="d-flex align-items-center w-100 h-25">
                    <div
                      className={`resize-bar bg-gray-600 bg-hover-primary bg-active-primary ${
                        isResizing ? "active" : ""
                      } h-100 rounded`}
                      style={{ cursor: "w-resize", width: 5 }}
                      onMouseDown={handleMouseDown}
                      onTouchStart={handleMouseDown}
                    ></div>
                  </div>
                  <ResizePopover
                    showPopover={showPopover}
                    position="bottom"
                    hidePopover={() => setShowPopover(false)}
                  ></ResizePopover>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        id="widgetControls"
        className="w-100 d-flex gap-3 my-3 widget-controls"
      >
        <div className="d-flex h-100 widget-contol">
          <div className="card w-100" style={{ height: "fit-content" }}>
            <div className="card-header">
              <div className="card-title">
                <h3>Settings</h3>
              </div>
              <div className="card-toolbar">
                <button className="btn btn-success btn-sm" onClick={handleSave}>
                  Save settings
                </button>
              </div>
            </div>
            <div className="card-body d-flex gap-5 flex-wrap">
              <div className="d-flex flex-column">
                <h4>Theme</h4>
                <select
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  className="form-select"
                  aria-label="Select example"
                >
                  <option value="light">Light</option>
                  <option value="dark">Dark</option>
                </select>
              </div>
              <div className="d-flex flex-column">
                <h4>Primary color: </h4>
                <div className="d-flex align-items-center bg-active-secondary active rounded-2">
                  <input
                    className="p-0 m-2"
                    type="color"
                    style={{
                      height: 25,
                      width: 25,
                      borderRadius: 5,
                      cursor: "pointer",
                      outline: "none",
                    }}
                    value={tempColorRef.current}
                    onChange={(e) => (tempColorRef.current = e.target.value)}
                    onBlur={handleMouseUp}
                    onAnimationEnd={handleMouseUp}
                  />
                  <span className="fw-light ms-2 me-3">{color}</span>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h4>
                  Height: <span className="fw-light">{height}px</span>
                </h4>

                <div
                  className="btn-group w-100 w-lg-50"
                  data-kt-buttons="true"
                  data-kt-buttons-target="[data-kt-button]"
                >
                  <label
                    className={`btn btn-outline btn-color-muted btn-active-success ${
                      height === 450 ? "active" : ""
                    }`}
                    data-kt-button="true"
                  >
                    <input
                      className="btn-check"
                      type="radio"
                      name="method"
                      onClick={() => setHeight(450)}
                    />
                    Small
                  </label>

                  <label
                    className={`btn btn-outline btn-color-muted btn-active-success ${
                      height === 600 ? "active" : ""
                    }`}
                    data-kt-button="true"
                  >
                    <input
                      className="btn-check"
                      type="radio"
                      name="method"
                      onClick={() => setHeight(600)}
                    />
                    Medium
                  </label>

                  <label
                    className={`btn btn-outline btn-color-muted btn-active-success ${
                      height === 750 ? "active" : ""
                    }`}
                    onClick={() => setHeight(750)}
                    data-kt-button="true"
                  >
                    <input
                      className="btn-check"
                      type="radio"
                      name="method"
                      onClick={() => setHeight(750)}
                    />
                    Large
                  </label>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h4>Allow the widget on these websites: </h4>
                <div className="d-flex flex-column gap-2">
                  {allowedOrigins.map((origin, index) => (
                    <div className="d-flex flex-column" key={index}>
                      {errors[index] && (
                        <div className="text-danger">{errors[index]}</div>
                      )}
                      <div className="d-flex gap-2">
                        <input
                          type="text"
                          className={`form-control form-control-sm ${
                            errors[index] ? "is-invalid" : ""
                          } ${index === 0 ? "me-11" : ""}`}
                          placeholder="https://example.com"
                          value={origin}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {index > 0 && (
                          <button
                            className="btn btn-danger btn-sm btn-icon"
                            onClick={() => handleRemove(index)}
                          >
                            <i className="ki-outline ki-trash"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="d-flex gap-2">
                    {allowedOrigins.length <= 2 && (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() =>
                          setAllowedOrigins([...allowedOrigins, ""])
                        }
                      >
                        Add Origin
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex widget-contol">
          <div className="card w-100">
            <div className="card-header">
              <div className="card-title">
                <h3>Paste this code to your html file</h3>
              </div>
            </div>
            <div className="card-body d-flex flex-row p-0">
              <CodeBlock
                text={code}
                language={"javascript"}
                showLineNumbers={true}
                theme={mode === "light" ? a11yLight : dracula}
                customStyle={{
                  width: "100%",
                  overflow: "auto",
                }}
              ></CodeBlock>
              <button
                className="btn btn-sm btn-icon btn-primary"
                style={{
                  position: "absolute",
                  right: 25,
                  marginTop: 10,
                  opacity: 0.5,
                }}
                onClick={copyCode}
              >
                <i className="ki-outline ki-copy fs-2"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <NotificationsContainer ref={notificationsRef} />
    </div>
  );
};

export default WidgetEditor;
